import { CustomError } from './customError';
import { ErrorCode } from './errorCode';

export class RfaWorkItemError extends CustomError {
  errorCode = ErrorCode.RfaWorkItemError;
  error?: Error;
  logFilePath?: string;
  workItemId?: string;

  constructor(message: string, error?: Error, cause?: any, workItemId?: string, logFilePath?: string) {
    super(message, cause);
    this.error = error;
    this.logFilePath = logFilePath;
    this.workItemId = workItemId;
  }
}
