import { RFAExportHistoryItem, RFAExportHistoryItemStatuses, updateRfaExportHistoryItem } from 'mid-addin-lib';
import { containsSpecialCharacters, logError } from 'mid-utils';
import text from './export-rfa.text.json';

export const LAST_EXPORTED_KEY = 'lastExported';

const sanitizeData = (data: { [key: string]: any }): any => {
  if (typeof data !== 'object' || data === null) {
    return data;
  }

  const validKeys = ['id', 'status', 'workItemId', 'logFilePath'];
  const sanitizedData: { [key: string]: any } = {};

  for (const key in data) {
    if (data.hasOwnProperty(key) && validKeys.includes(key)) {
      sanitizedData[key] = data[key];
    }
  }

  return sanitizedData;
};

export const checkForCancelledExport = async (): Promise<void> => {
  const lastExportedJson = window.localStorage.getItem(LAST_EXPORTED_KEY);
  if (lastExportedJson) {
    try {
      const lastExported: RFAExportHistoryItem = JSON.parse(lastExportedJson);
      const sanitizedLastExported = sanitizeData(lastExported);

      if (sanitizedLastExported.status === RFAExportHistoryItemStatuses.PENDING) {
        await updateRfaExportHistoryItem(sanitizedLastExported.id, {
          status: RFAExportHistoryItemStatuses.FAILED,
          workItemId: sanitizedLastExported.workItemId,
          logFilePath: sanitizedLastExported.logFilePath,
        });
      }
    } catch (e) {
      logError(e);
    }
  }
};

export const validateFileName = (fileName: string): string | null => {
  if (containsSpecialCharacters(fileName)) {
    return text.propertiesTab.fileNameSpecialCharactersError;
  }

  if (fileName === '') {
    return text.propertiesTab.fileNameEmptyError;
  }
  return null;
};
