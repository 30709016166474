import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import {
  createRfaExportHistoryItem,
  exportActiveDocumentAsBCD,
  exportRfa,
  FileFilter,
  getActiveDocumentInfo,
  getActiveModelStateName,
  getRevitClassification,
  getRfaExportHistoryItem,
  openFileLocation,
  RFAExportHistoryItem,
  RFAExportHistoryItemStatuses,
  saveFileDialog,
  updateRfaExportHistoryItem,
} from 'mid-addin-lib';
import useDatastore from '../../stores/Datastore';
import { logError, RfaWorkItemError } from 'mid-utils';
import { RFAExportWorkItem, RFAExportWorkItemStatuses } from 'mid-types';
import { LAST_EXPORTED_KEY, validateFileName } from 'utils';
import { RevitVersion } from 'types';

interface UsePropertiesState {
  fileNameError: string | null;
  modelState: string;
  isRevitClassificationValid: boolean;
  handleFileNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRevitVersionChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  handleLocationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectLocation: () => void;
  handleShowInLocationChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleExport: () => void;
}

const usePropertiesTab = (): UsePropertiesState => {
  const {
    fileName,
    revitVersion,
    downloadFolderPath,
    showInLocation,
    defaultProperties,
    isInitialized,
    setIsInitialized,
    setIsHistoryTabVisitedWhilePolling,
    setFileName,
    setRevitVersion,
    setDownloadFolderPath,
    setShowInLocation,
    setDefaultProperties,
    setIsPolling,
  } = useDatastore(
    useShallow((state) => ({
      fileName: state.fileName,
      revitVersion: state.revitVersion,
      downloadFolderPath: state.downloadFolderPath,
      showInLocation: state.showInLocation,
      defaultProperties: state.defaultProperties,
      isInitialized: state.isInitialized,
      setIsHistoryTabVisitedWhilePolling: state.setIsHistoryTabVisitedWhilePolling,
      setFileName: state.setFileName,
      setIsInitialized: state.setIsInitialized,
      setRevitVersion: state.setRevitVersion,
      setDownloadFolderPath: state.setDownloadFolderPath,
      setShowInLocation: state.setShowInLocation,
      setDefaultProperties: state.setDefaultProperties,
      setIsPolling: state.setIsPolling,
    })),
  );

  const [modelState, setModelState] = useState<string>('');
  const [fileNameError, setFileNameError] = useState<string | null>(null);
  const [isRevitClassificationValid, setRevitClassificationValid] = useState(true);

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFileName = event.target.value;
    const fileNameError = validateFileName(newFileName);
    setFileNameError(fileNameError);
    setFileName(newFileName);
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDownloadFolderPath(event.target.value);
  };

  const handleSelectLocation = async () => {
    const rfaFilter: FileFilter[] = [{ name: 'Revit Family(*.rfa)', expression: '*.rfa' }];
    const fileLocation = await saveFileDialog(downloadFolderPath, fileName, rfaFilter);
    if (fileLocation) {
      const downloadFolderPath = fileLocation.split('\\').slice(0, -1).join('\\');
      setDownloadFolderPath(downloadFolderPath);
    }
  };

  const handleRevitVersionChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setRevitVersion(value as RevitVersion);
  };

  const handleShowInLocationChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShowInLocation(checked);
  };

  const handleExport = async () => {
    setIsPolling(true);

    let historyItem: RFAExportHistoryItem | null = null;
    let rfaWorkItem: RFAExportWorkItem | null = null;

    try {
      historyItem = await createRfaExportHistoryItem(fileName, revitVersion);
      window.localStorage.setItem(LAST_EXPORTED_KEY, JSON.stringify(historyItem));
      const bcdFilePath = await exportActiveDocumentAsBCD();

      rfaWorkItem = await exportRfa(historyItem, bcdFilePath, revitVersion, downloadFolderPath, fileName);

      if (showInLocation && rfaWorkItem.status === RFAExportWorkItemStatuses.SUCCESS) {
        const fullPathToFile = `${downloadFolderPath}\\${fileName}.rfa`;
        await openFileLocation(fullPathToFile);
      }
    } catch (error) {
      if (historyItem) {
        await updateRfaExportHistoryItem(historyItem.id, {
          status: RFAExportHistoryItemStatuses.FAILED,
          workItemId: error instanceof RfaWorkItemError ? error.workItemId : '',
          logFilePath: error instanceof RfaWorkItemError ? error.logFilePath : '',
        });
      }
      logError(error);
    } finally {
      setIsPolling(false);
      setIsHistoryTabVisitedWhilePolling(false);
      if (historyItem) {
        const lastExpoted = await getRfaExportHistoryItem(historyItem.id);
        window.localStorage.setItem(LAST_EXPORTED_KEY, JSON.stringify(lastExpoted));
      }
    }
  };

  useEffect(() => {
    async function validateRevitClassification() {
      const revitClassification = await getRevitClassification();
      setRevitClassificationValid(revitClassification.isValid);
    }
    validateRevitClassification();
    // Run the validation on focus after bim properties are updated
    window.addEventListener('focus', validateRevitClassification);

    return () => {
      window.removeEventListener('focus', validateRevitClassification);
    };
  }, []);

  useEffect(() => {
    async function fetchInitialData() {
      if (!isInitialized) {
        const documentInfo = await getActiveDocumentInfo();
        const documentName = documentInfo.name.split('.').slice(0, -1).join('.');
        setFileName(documentName);
        setDownloadFolderPath(documentInfo.location);

        setDefaultProperties({
          ...defaultProperties,
          fileName: documentName,
          downloadFolderPath: documentInfo.location,
        });
      }
      if (!modelState) {
        const modelState = await getActiveModelStateName();
        setModelState(modelState);
      }
      setIsInitialized(true);
    }
    fetchInitialData();
  }, [
    defaultProperties,
    isInitialized,
    modelState,
    setDefaultProperties,
    setDownloadFolderPath,
    setFileName,
    setIsInitialized,
  ]);

  return {
    fileNameError,
    modelState,
    isRevitClassificationValid,
    handleFileNameChange,
    handleLocationChange,
    handleRevitVersionChange,
    handleSelectLocation,
    handleShowInLocationChange,
    handleExport,
  };
};

export default usePropertiesTab;
