import { ErrorCode } from '../errorCode';
import { CustomError } from '../customError';

type CauseType = {
  filePath?: string;
};

export class ExtractZipFileError extends CustomError {
  errorCode = ErrorCode.ExtractZipFileError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
