import Box from '@weave-mui/box';
import FormControl from '@weave-mui/form-control';
import IconButton from '@weave-mui/icon-button';
import Notification from '@weave-mui/notification';
import Stack from '@weave-mui/stack';
import { styled } from '@weave-mui/styles';

export const StackWrapper = styled(Stack)`
  overflow: auto;
`;

export const NotificationWrapper = styled(Notification)`
  & .MuiAlert-message {
    flex: 1;
  }
`;

export const IconButtonWrapper = styled(IconButton)`
  padding-inline: 0.5rem;
`;

export const NotificationContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  gap: 0.5rem;
`;

export const SelectWrapper = styled(FormControl)`
  margin-left: auto;
`;
