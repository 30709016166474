import { RFAExportHistoryItem } from 'mid-addin-lib';
import { DefaultProperties, HistoryItemsSortOrder, historyItemsSortOrders, RevitVersion, revitVersions } from '../types';
import { create } from 'zustand';

interface UseDatastoreState {
  fileName: string;
  setFileName: (fileName: string) => void;

  revitVersion: RevitVersion;
  setRevitVersion: (revitVersion: RevitVersion) => void;

  downloadFolderPath: string;
  setDownloadFolderPath: (downloadFolderPath: string) => void;

  isInitialized: boolean;
  setIsInitialized: (isInitialized: boolean) => void;

  showInLocation: boolean;
  setShowInLocation: (showInLocation: boolean) => void;

  isPolling: boolean;
  setIsPolling: (isPolling: boolean) => void;

  isHistoryTabVisitedWhilePolling: boolean;
  setIsHistoryTabVisitedWhilePolling: (isHistoryTabVisitedWhilePolling: boolean) => void;

  defaultProperties: DefaultProperties;
  setDefaultProperties: (defaultProperties: DefaultProperties) => void;

  isDefaultProperties: () => boolean;

  restoreDefaultProperties: () => void;

  rfaExportHistoryItems: RFAExportHistoryItem[];
  setRfaExportHistoryItems: (rfaExportHistoryItems: RFAExportHistoryItem[]) => void;

  historyItemsSortOrder: HistoryItemsSortOrder;
  setHistoryItemsSortOrder: (historyItemsSortOrder: HistoryItemsSortOrder) => void;
}

const useDatastore = create<UseDatastoreState>((set, get) => ({
  fileName: '',
  setFileName: (fileName) => set({ fileName }),

  isInitialized: false,
  setIsInitialized: (isInitialized) => set({ isInitialized }),

  revitVersion: revitVersions[2024],
  setRevitVersion: (revitVersion) => set({ revitVersion }),

  downloadFolderPath: '',
  setDownloadFolderPath: (downloadFolderPath) => set({ downloadFolderPath }),

  showInLocation: false,
  setShowInLocation: (showInLocation) => set({ showInLocation }),

  isPolling: false,
  setIsPolling: (isPolling) => set({ isPolling }),

  isHistoryTabVisitedWhilePolling: false,
  setIsHistoryTabVisitedWhilePolling: (isHistoryTabVisitedWhilePolling) => set({ isHistoryTabVisitedWhilePolling }),

  defaultProperties: {
    fileName: '',
    revitVersion: revitVersions[2024],
    downloadFolderPath: '',
    showInLocation: false,
  },
  setDefaultProperties: (defaultProperties) => set({ defaultProperties }),

  isDefaultProperties: () =>
    get().fileName === get().defaultProperties.fileName &&
    get().revitVersion === get().defaultProperties.revitVersion &&
    get().downloadFolderPath === get().defaultProperties.downloadFolderPath &&
    get().showInLocation === get().defaultProperties.showInLocation,

  restoreDefaultProperties: () => {
    set({ fileName: get().defaultProperties.fileName });
    set({ revitVersion: get().defaultProperties.revitVersion });
    set({ downloadFolderPath: get().defaultProperties.downloadFolderPath });
    set({ showInLocation: get().defaultProperties.showInLocation });
  },

  rfaExportHistoryItems: [],
  setRfaExportHistoryItems: (rfaExportHistoryItems) => set({ rfaExportHistoryItems }),

  historyItemsSortOrder: historyItemsSortOrders.NEWEST_FIRST,
  setHistoryItemsSortOrder: (historyItemsSortOrder) => set({ historyItemsSortOrder }),
}));

export default useDatastore;
