export type UploadBcdURLsPayload = {
  fileName: string;
  parts: number;
};

export type CompleteBcdUploadPayload = {
  engineVersion: string;
  objectKey: string;
  uploadKey: string;
  fileName: string;
  fileSize: number;
};

export type CompleteBcdUploadResponse = {
  resultsUrl: string;
  workitemId: string;
};

export const RFAExportWorkItemStatuses = {
  PENDING: 'pending',
  IN_PROGRESS: 'inprogress',
  CANCELLED: 'cancelled',
  FAILED_LIMIT_DATA_SIZE: 'failedLimitDataSize',
  FAILED_LIMIT_PROCESSING_TIME: 'failedLimitProcessingTime',
  FAILED_DOWNLOAD: 'failedDownload',
  FAILED_INSTRUCTIONS: 'failedInstructions',
  FAILED_UPLOAD: 'failedUpload',
  FAILED_UPLOAD_OPTIONAL: 'failedUploadOptional',
  SUCCESS: 'success',
} as const;

export type RFAExportWorkItemStatus = (typeof RFAExportWorkItemStatuses)[keyof typeof RFAExportWorkItemStatuses];

export type RFAExportWorkItem = {
  id: string;
  reportUrl: string;
  stats: {
    bytesDownloaded: number;
    bytesUploaded: number;
    timeDownloadStarted: string;
    timeFinished: string;
    timeInstructionsEnded: string;
    timeInstructionsStarted: string;
    timeQueued: string;
    timeUploadEnded: string;
  };
  status: RFAExportWorkItemStatus;
};
