import { LogLevel } from '../interfaces/log';
import { logToFile } from './sharedServices/tools';

/**
 * A generic polling function with a fixed interval strategy.
 *
 * @param pollFunction - The function to poll, which returns a promise.
 * This function should take any number of arguments and return a promise of the result.
 * @param conditionFunction - The function that checks if the polling condition is met.
 * It takes the result of pollFunction as an argument and returns a boolean indicating whether polling should stop.
 * @param interval - The interval in milliseconds between polling attempts.
 * @param maxRetries - The maximum number of retries allowed if pollFunction fails.
 * @param pollArgs - The arguments to be passed to pollFunction.
 * @returns A promise that resolves when the polling stops either because the condition is met
 * or the maximum retries are reached. The promise rejects if the maximum retries are exceeded.
 */
export const startFixedIntervalPolling = async <T, A extends any[]>(
  pollFunction: (...args: A) => Promise<T>,
  conditionFunction: (input: T) => boolean,
  interval: number,
  maxRetries: number,
  ...pollArgs: A
): Promise<T> => {
  let retries = 0;
  let pollingTimeout: number | null = null;

  // Validate input parameters
  if (interval <= 0) {
    throw new Error('Interval must be greater than 0');
  }
  if (maxRetries < 0) {
    throw new Error('Max retries must be non-negative');
  }

  return new Promise<T>((resolve, reject) => {
    const poll = async (): Promise<void> => {
      try {
        const data = await pollFunction(...pollArgs);

        if (conditionFunction(data)) {
          logToFile('Condition met, stopping polling.', LogLevel.Info);
          stopPolling();
          resolve(data); // Resolve the promise when the condition is met
          return;
        }

        logToFile(`Condition not met (retry ${retries + 1}/${maxRetries}), continuing polling.`, LogLevel.Info);
      } catch (error) {
        logToFile(`Polling error (retry ${retries + 1}/${maxRetries}): ${error}`, LogLevel.Error);
      } finally {
        retries++;
        if (retries >= maxRetries) {
          logToFile('Max retries reached. Stopping polling.', LogLevel.Info);
          stopPolling();
          reject(new Error('Max retries reached')); // Reject the promise when max retries are reached
        } else {
          pollingTimeout = window.setTimeout(poll, interval);
        }
      }
    };

    const stopPolling = (): void => {
      if (pollingTimeout !== null) {
        clearTimeout(pollingTimeout);
        pollingTimeout = null;
      }
    };

    // Start polling immediately
    logToFile('Start polling immediately', LogLevel.Info);
    poll();
  });
};
