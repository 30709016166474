import './App.css';
import { GlobalContainer, GlobalPaperWrapper } from './styles/Common.styles';
import ThemeInitializer from './theme/ThemeInitializer';
import Tabs from './components/Tabs/Tabs';
import { useEffect, useState } from 'react';
import { browserApiService, getCurrentAuthToken, getEnvironment, getRfaExportHistoryItems } from 'mid-addin-lib';
import { inversifyContainer, InversifyTypes, registerApiBaseURL, registerAuthHandler, registerEnv } from 'mid-api-services';
import useDatastore from 'stores/Datastore';
import { checkForCancelledExport } from './utils';
import { useShallow } from 'zustand/react/shallow';

const App: React.FC = () => {
  const setRfaExportHistoryItems = useDatastore(useShallow((state) => state.setRfaExportHistoryItems));

  useEffect(() => {
    async function fetchInitialData() {
      await checkForCancelledExport();
      const items = await getRfaExportHistoryItems();
      setRfaExportHistoryItems(items);
    }
    fetchInitialData();
  }, [setRfaExportHistoryItems]);

  const [apiServicesInitialized, setApiServicesInitialized] = useState(false);
  useEffect(() => {
    const registerDependency = async () => {
      const env = await getEnvironment();

      const dcApiBaseURL = await browserApiService.getDcApiUrl();

      if (!inversifyContainer.isBound(InversifyTypes.Env)) {
        registerEnv(env);
      }

      if (!inversifyContainer.isBound(InversifyTypes.AuthHandler)) {
        registerAuthHandler(() => getCurrentAuthToken());
      }

      if (!inversifyContainer.isBound(InversifyTypes.DcApiBaseURL)) {
        registerApiBaseURL(InversifyTypes.DcApiBaseURL, dcApiBaseURL);
      }

      setApiServicesInitialized(true);
    };
    registerDependency();
  }, []);

  return (
    <ThemeInitializer>
      {apiServicesInitialized && (
        <GlobalPaperWrapper level="200">
          <GlobalContainer>
            <Tabs />
          </GlobalContainer>
        </GlobalPaperWrapper>
      )}
    </ThemeInitializer>
  );
};

export default App;
