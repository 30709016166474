import text from '../../../export-rfa.text.json';
import { RFAExportHistoryItemStatuses } from 'mid-addin-lib';
import { RFAExportHistoryItem } from 'mid-addin-lib';
import { EllipsisSUI, ExportMoveS } from '@weave-mui/icons-weave';
import LinearProgress from '@weave-mui/linear-progress';
import MenuList from '@weave-mui/menu-list';
import MenuItem from '@weave-mui/menu-item';
import Popper from '@weave-mui/popper';
import Typography from '@weave-mui/typography';
import { IconButtonWrapper, NotificationContentWrapper, NotificationWrapper, StackWrapper } from '../HistoryTab.styles';
import useDatastore from '../../../stores/Datastore';
import { timeAgo } from '../utils';
import Paper from '@weave-mui/paper';
import useHistoryItems from './useHistoryItems';

const NotificationContent: React.FC<{ item: RFAExportHistoryItem }> = ({ item }) => {
  switch (item.status) {
    case RFAExportHistoryItemStatuses.SUCCESS:
      return (
        <NotificationContentWrapper>
          <Typography>
            {text.historyTab.complete}
            {item.rfaFileName}
          </Typography>
        </NotificationContentWrapper>
      );
    case RFAExportHistoryItemStatuses.FAILED:
      return (
        <NotificationContentWrapper>
          <Typography>
            {text.historyTab.failed}
            {item.rfaFileName}
          </Typography>
        </NotificationContentWrapper>
      );
    case RFAExportHistoryItemStatuses.PENDING:
      return (
        <NotificationContentWrapper>
          <Typography>
            {text.historyTab.exporting}
            {item.rfaFileName}
          </Typography>
          <LinearProgress />
        </NotificationContentWrapper>
      );
  }
};

const HistoryItems: React.FC = () => {
  const rfaExportHistoryItems = useDatastore((state) => state.rfaExportHistoryItems);
  const { openMenuId, anchorRefs, handleDeleteExportRecord, handleMenuClick, handleOnClose, handleShowLogInFolderClick } =
    useHistoryItems();

  return (
    <StackWrapper>
      {rfaExportHistoryItems.map((item) => (
        <NotificationWrapper
          key={item.id}
          action={
            item.status !== RFAExportHistoryItemStatuses.PENDING && (
              <>
                <IconButtonWrapper onClick={handleMenuClick(item.id)}>
                  <EllipsisSUI />
                </IconButtonWrapper>
                <Popper
                  anchorEl={anchorRefs.current[item.id]}
                  open={openMenuId === item.id}
                  placement="bottom-end"
                  onClose={handleOnClose(item.id)}
                  arrow
                >
                  <Paper elevation="low">
                    <MenuList>
                      <MenuItem disabled={!item.logFilePath} onClick={handleShowLogInFolderClick(item.logFilePath)}>
                        {text.historyTab.showLogInFolder}
                      </MenuItem>
                      <MenuItem onClick={handleDeleteExportRecord(item.id)}>{text.historyTab.deleteExportRecord}</MenuItem>
                    </MenuList>
                  </Paper>
                </Popper>
              </>
            )
          }
          icon={item.status === RFAExportHistoryItemStatuses.PENDING ? <ExportMoveS /> : undefined}
          timestamp={timeAgo(item.updatedAt)}
          severity={
            item.status === RFAExportHistoryItemStatuses.SUCCESS
              ? 'success'
              : item.status === RFAExportHistoryItemStatuses.FAILED
                ? 'error'
                : 'info'
          }
        >
          <NotificationContent item={item} />
        </NotificationWrapper>
      ))}
    </StackWrapper>
  );
};

export default HistoryItems;
