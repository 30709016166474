import './theme/typography.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ENVIRONMENT } from 'mid-types';
import { http } from 'msw';
import { mswDcApiHandlers, mswForgeApiHandlers, mswMiscellaneousWebHandlers } from 'mid-utils';
import { defineWindowHostApiMock, mockHostApi } from 'mid-addin-lib';
import { setupWorker } from 'msw/browser';
import reportWebVitals from 'reportWebVitals';

if (
  // figure out how to get this variable set by CYPRESS not by env file
  import.meta.env.VITE_CYPRESS_BUILD === 'true' ||
  import.meta.env.VITE_ENVIRONMENT === ENVIRONMENT.MOCK
) {
  // TODO: add mswUnhandledPathHandler to handlers list & add unmocked handlers as needed
  // If added now, the app will crash when run in VITE_ENVIRONMENT === ENVIRONMENT.MOCK mode
  // https://jira.autodesk.com/browse/TRADES-5833
  const handlers = [...mswForgeApiHandlers, ...mswDcApiHandlers, ...mswMiscellaneousWebHandlers];
  const mockWorker = setupWorker(...handlers);

  // Make the `worker` and `http` references available globally for cypress testing
  window.msw = { mockWorker, http };

  // Mocking HostApi library
  defineWindowHostApiMock(window, mockHostApi);

  // Starting msw mock worker to intercept FORGE API calls
  await mockWorker.start();
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);

reportWebVitals();
