import { createTheme, getTheme, Theme, ThemeProvider, themes, densities } from '@weave-mui/styles';
import { ReactNode, useEffect, useState } from 'react';

interface ThemeWrapperProps {
  children: ReactNode;
}

const muiTheme = createTheme({});

const ThemeInitializer: React.FC<ThemeWrapperProps> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(muiTheme);
  const [isThemeLoaded, setIsThemeLoaded] = useState(false);

  useEffect(() => {
    async function loadTheme() {
      const weaveTheme = await getTheme(themes.LIGHT_GRAY, densities.HIGH);
      setTheme(createTheme(muiTheme, weaveTheme));
      setIsThemeLoaded(true);
    }
    loadTheme();
  }, []);

  return <>{isThemeLoaded && <ThemeProvider theme={theme}>{children}</ThemeProvider>}</>;
};

export default ThemeInitializer;
