import { inversifyContainer, DcApiService, InversifyTypes } from 'mid-api-services';
import {
  RFAExportHistoryItem,
  RFAExportHistoryItemStatuses,
  UpdateRFAExportHistoryItemPayload,
} from '../../interfaces/exportRfa';
import { webview2BrowserApiService } from '../../services';
import { startFixedIntervalPolling } from '../polling';
import { uploadBcdFileAndGenerateRfa } from '../sharedServices/upload';
import { RFAExportWorkItem, RFAExportWorkItemStatuses } from 'mid-types';
import { FileFilter } from '../../interfaces/fileSystem';
import { downloadFileFromUrl, extractZipFileToFolder } from './filesystem';
import { deleteFile } from '../sharedServices/filesystem';
import { RfaWorkItemError } from 'mid-utils';
import text from '../../mid-addin-lib.text.json';

export const isActiveDocumentTypeValid = async (): Promise<boolean> => {
  return await webview2BrowserApiService.isActiveDocumentTypeValid();
};

export const getActiveModelStateName = async (): Promise<string> => {
  return await webview2BrowserApiService.getActiveModelStateName();
};

export const deleteRfaExportHistoryItem = async (id: string): Promise<void> => {
  return await webview2BrowserApiService.deleteRfaExportHistoryItem(id);
};

export const deleteRfaExportHistoryItems = async (): Promise<void> => {
  return await webview2BrowserApiService.deleteRfaExportHistoryItems();
};

export const updateRfaExportHistoryItem = async (
  id: string,
  payload: UpdateRFAExportHistoryItemPayload,
): Promise<RFAExportHistoryItem> => {
  const result = await webview2BrowserApiService.updateRfaExportHistoryItem(id, payload);

  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const createRfaExportHistoryItem = async (
  rfaFileName: string,
  revitVersion: string,
): Promise<RFAExportHistoryItem> => {
  const result = await webview2BrowserApiService.createRfaExportHistoryItem(rfaFileName, revitVersion);

  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const getRfaExportHistoryItem = async (id: string): Promise<RFAExportHistoryItem> => {
  const result = await webview2BrowserApiService.getRfaExportHistoryItem(id);

  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const getRfaExportHistoryItems = async (): Promise<RFAExportHistoryItem[]> => {
  const result = await webview2BrowserApiService.getRfaExportHistoryItems();

  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const getRFAExportHistoryLogsFolderPath = async (): Promise<string> => {
  return await webview2BrowserApiService.getRFAExportHistoryLogsFolderPath();
};

export const exportActiveDocumentAsBCD = async (): Promise<string> => {
  const result = await webview2BrowserApiService.exportActiveDocumentAsBCD();

  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const openFileLocation = async (path: string): Promise<boolean> => {
  return await webview2BrowserApiService.openFileLocation(path);
};

export const saveFileDialog = async (
  initialDirectory: string,
  initialFileName: string,
  filter: FileFilter[],
): Promise<string> => {
  const fileFilter = JSON.stringify(filter);
  return await webview2BrowserApiService.saveFileDialog(initialDirectory, initialFileName, fileFilter);
};

// 30 seconds * 12 tries = 6 minute timeout
const POLLING_INTERVAL = 30000;
const MAX_RETRIES = 12;

export const exportRfa = async (
  historyItem: RFAExportHistoryItem,
  bcdFilePath: string,
  revitVersion: string,
  downloadFolderPath: string,
  fileName: string,
): Promise<RFAExportWorkItem> => {
  const rfaZipFilePath = `${downloadFolderPath}\\${fileName}.zip`;
  const rfaFilePath = `${downloadFolderPath}\\${fileName}.rfa`;

  const rfaWorkItemResult = await uploadBcdFileAndGenerateRfa(bcdFilePath, revitVersion, fileName);

  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);

  // Polling for RFA Export Work Item
  const shouldRFAExportItemPollingContinue = (item: RFAExportWorkItem): boolean =>
    Object.values(RFAExportWorkItemStatuses).includes(item.status) &&
    item.status !== RFAExportWorkItemStatuses.PENDING &&
    item.status !== RFAExportWorkItemStatuses.IN_PROGRESS;

  const rfaWorkItem = await startFixedIntervalPolling(
    dcApiService.getRfaExportWorkItemStatus,
    shouldRFAExportItemPollingContinue,
    POLLING_INTERVAL,
    MAX_RETRIES,
    rfaWorkItemResult.workitemId,
  );

  let logFolderPath = '';
  let logFilePath = '';

  try {
    // Download Log File
    logFolderPath = await getRFAExportHistoryLogsFolderPath();
    const fileSafeTimeStamp = rfaWorkItem.stats.timeFinished.replace(/[-:.TZ]/g, '');
    logFilePath = `${logFolderPath}\\${fileName}_${fileSafeTimeStamp}.txt`;
    await downloadFileFromUrl(rfaWorkItem.reportUrl, logFilePath);

    if (rfaWorkItem.status === RFAExportWorkItemStatuses.SUCCESS) {
      await downloadFileFromUrl(rfaWorkItemResult.resultsUrl, rfaZipFilePath);

      // Delete already existing RFA file, if any
      await deleteFile(rfaFilePath);
      await extractZipFileToFolder(rfaZipFilePath, downloadFolderPath);
      await deleteFile(rfaZipFilePath);

      await updateRfaExportHistoryItem(historyItem.id, {
        workItemId: rfaWorkItem.id,
        status: RFAExportHistoryItemStatuses.SUCCESS,
        logFilePath,
      });
    } else {
      await updateRfaExportHistoryItem(historyItem.id, {
        workItemId: rfaWorkItem.id,
        status: RFAExportHistoryItemStatuses.FAILED,
        logFilePath,
      });
    }

    return rfaWorkItem;
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new RfaWorkItemError(error.message, error, error.cause, rfaWorkItem.id, logFilePath);
    }
    throw new RfaWorkItemError(text.failedToExportRfa);
  }
};
