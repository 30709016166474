import { TabWrapper, TooltipWrapper } from 'styles/Common.styles';
import text from '../../export-rfa.text.json';
import { FolderOpenS, InfoS } from '@weave-mui/icons-weave';
import MenuItem from '@weave-mui/menu-item';
import Select from '@weave-mui/select';
import Typography from '@weave-mui/typography';
import { SelectWrapper } from './HistoryTab.styles';
import useDatastore from 'stores/Datastore';
import Box from '@weave-mui/box';
import Button from '@weave-mui/button';
import { useShallow } from 'zustand/react/shallow';
import { useTheme } from '@weave-mui/styles';
import useHistoryTab from './useHistoryTab';
import HistoryItems from './HistoryItems/HistoryItems';
import { historyItemsSortOrders } from 'types';

const HistoryTab: React.FC = () => {
  const theme = useTheme();
  const { historyItemsSortOrder, rfaExportHistoryItems, isPolling } = useDatastore(
    useShallow((state) => ({
      isPolling: state.isPolling,
      historyItemsSortOrder: state.historyItemsSortOrder,
      rfaExportHistoryItems: state.rfaExportHistoryItems,
    })),
  );

  const { initialDataLoading, handleClearAllExportRecords, handleSort } = useHistoryTab();

  return (
    <TabWrapper>
      {!initialDataLoading && rfaExportHistoryItems.length ? (
        <>
          <Box display="flex" gap="0.5rem">
            <Typography variant="body1Medium" alignSelf="center">
              {rfaExportHistoryItems.length}{' '}
              {rfaExportHistoryItems.length === 1 ? text.historyTab.export : text.historyTab.exports}
            </Typography>
            <TooltipWrapper
              placement="right"
              size="medium"
              arrow
              title={
                <Box display="flex" flexDirection="column" gap="1rem" alignItems="flex-start">
                  <Typography gutterBottom>{text.historyTab.numberOfExportsTooltip}</Typography>
                  <Button color="secondary" variant="outlined" onClick={handleClearAllExportRecords} disabled={isPolling}>
                    {text.historyTab.clearExportHistory}
                  </Button>
                </Box>
              }
            >
              <InfoS color="primary" />
            </TooltipWrapper>
            <SelectWrapper>
              <Select
                variant="outlined"
                value={historyItemsSortOrder}
                onChange={handleSort}
                MenuProps={{
                  PaperProps: {
                    sx: { backgroundColor: `${theme.tokens?.semantic['background-color'].surface[100]} !important` },
                  },
                }}
              >
                <MenuItem value={historyItemsSortOrders.NEWEST_FIRST}>{text.historyTab.newestFirst}</MenuItem>
                <MenuItem value={historyItemsSortOrders.OLDEST_FIRST}>{text.historyTab.oldestFirst}</MenuItem>
              </Select>
            </SelectWrapper>
          </Box>
          <HistoryItems />
        </>
      ) : (
        <>
          {!initialDataLoading && !isPolling && (
            <Box display="flex" justifyContent="center">
              <Box textAlign="center">
                <FolderOpenS color="primary" />
                <Typography variant="body2" gutterBottom>
                  {text.historyTab.noExportHisory}
                </Typography>
                <Typography>{text.historyTab.noExportHisoryInfo}</Typography>
              </Box>
            </Box>
          )}
        </>
      )}
    </TabWrapper>
  );
};

export default HistoryTab;
