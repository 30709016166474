import { openFileLocation, deleteRfaExportHistoryItem } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import { useRef, useState } from 'react';
import useDatastore from '../../../stores/Datastore';
import { useShallow } from 'zustand/react/shallow';

interface UseHistoryItems {
  openMenuId: string | null;
  anchorRefs: React.MutableRefObject<{ [key: string]: HTMLButtonElement | null }>;
  handleDeleteExportRecord: (id: string) => () => void;
  handleMenuClick: (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleOnClose: (id: string) => () => void;
  handleShowLogInFolderClick: (logPath?: string) => () => void;
}

const useHistoryItems = (): UseHistoryItems => {
  const { rfaExportHistoryItems, setRfaExportHistoryItems } = useDatastore(
    useShallow((state) => ({
      isPolling: state.isPolling,
      rfaExportHistoryItems: state.rfaExportHistoryItems,
      isHistoryTabVisitedWhilePolling: state.isHistoryTabVisitedWhilePolling,
      setRfaExportHistoryItems: state.setRfaExportHistoryItems,
      setIsHistoryTabVisitedWhilePolling: state.setIsHistoryTabVisitedWhilePolling,
    })),
  );

  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const anchorRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});

  const handleMenuClick = (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorRefs.current[id] = event.currentTarget;

    // We need to ensure that any previous menu is closed and has it's anchor ref removed
    // before opening a new one
    setTimeout(() => {
      setOpenMenuId(id);
    }, 0);
  };

  const handleOnClose = (id: string) => () => {
    anchorRefs.current[id] = null;
    setOpenMenuId(null);
  };

  const handleShowLogInFolderClick = (logPath?: string) => () => {
    if (!logPath) {
      return;
    }
    openFileLocation(logPath);
  };

  const handleDeleteExportRecord = (id: string) => async () => {
    try {
      await deleteRfaExportHistoryItem(id);
      const newItems = rfaExportHistoryItems.filter((item) => item.id !== id);
      delete anchorRefs.current[id];
      setRfaExportHistoryItems(newItems);
    } catch (e) {
      logError(e);
    }
  };

  return {
    openMenuId,
    anchorRefs,
    handleDeleteExportRecord,
    handleMenuClick,
    handleOnClose,
    handleShowLogInFolderClick,
  };
};

export default useHistoryItems;
