import { RFAExportHistoryItem } from 'mid-addin-lib';
import { HistoryItemsSortOrder, historyItemsSortOrders } from 'types';

export const timeAgo = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();

  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

  if (seconds < 60) {
    return rtf.format(-seconds, 'second');
  }
  if (minutes < 60) {
    return rtf.format(-minutes, 'minute');
  }
  if (hours < 24) {
    return rtf.format(-hours, 'hour');
  }
  if (days < 30) {
    return rtf.format(-days, 'day');
  }
  return date.toLocaleDateString(); // If it's more than a month ago, show the date
};

export const sortItems = (items: RFAExportHistoryItem[], sortOrder: HistoryItemsSortOrder): RFAExportHistoryItem[] => {
  return [...items].sort((a, b) => {
    if (sortOrder === historyItemsSortOrders.NEWEST_FIRST) {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    }
    return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
  });
};
