export const revitVersions = {
  '2024': '2024',
  '2025': '2025',
} as const;

export type RevitVersion = (typeof revitVersions)[keyof typeof revitVersions];

export interface DefaultProperties {
  fileName: string;
  revitVersion: RevitVersion;
  downloadFolderPath: string;
  showInLocation: boolean;
}

export const historyItemsSortOrders = {
  NEWEST_FIRST: 'newestFirst',
  OLDEST_FIRST: 'oldestFirst',
} as const;

export type HistoryItemsSortOrder = (typeof historyItemsSortOrders)[keyof typeof historyItemsSortOrders];
